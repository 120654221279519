<template>
  <BaseDialog
    :propDialog="dialog"
    :max-width="400"
    :title="`${invoice.tableName} - ${invoice.customerName}`"
    :actionable="false"
    @closeDialog="setDialog"
    ><div class="d-flex justify-center">
      <VueQr v-if="$socket.connected" :text="invoiceLink"></VueQr></div
  ></BaseDialog>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: 'InvoiceQR',

  components: {
    VueQr
  },

  props: {
    dialog: Boolean,
    invoice: Object
  },

  computed: {
    invoiceLink() {
      return `https://${location.hostname}?invoice=${this.invoice._id}`
    }
  },

  methods: {
    setDialog(val) {
      this.$emit('update:dialog', val)
    }
  }
}
</script>

<style scoped></style>
